<template>
    <div>
        <b-table responsive bordered hover show-empty :busy="false" :items="merchant" :fields="fields" class="mb-3">
            <template #empty="scope"> Data tidak ditemukan </template>
            <template v-slot:cell(status)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <div v-if="row.item.status === 1" class="badge badge-primary">
                            Diajukan
                        </div>
                        <div v-if="row.item.status === 2" class="badge badge-success">
                            Active
                        </div>
                        <div v-if="row.item.status === 3" class="badge badge-danger">
                            Ditolak
                        </div>
                        <div v-if="row.item.status === 4" class="badge badge-secondary">
                            Non Active
                        </div>
                    </div>

                    <!-- <b-form-checkbox checked name="check-button" switch>
                    </b-form-checkbox> -->
                </div>
            </template>
            <template v-slot:cell(nama_merchant)="row">
                <div>
                    <a class="d-block" :href="row.item.link_merchant" target="_blank" rel="noopener noreferrer">
                        <span>{{ row.item.nama_merchant }}</span>
                    </a>
                    <template v-if="row.item.supplier">
                        <small>
                            <b>
                                {{
                                    row.item.supplier.nama
                                }}
                            </b>
                        </small>
                    </template>
                </div>
            </template>
            <template v-slot:cell(actions)="row">
                <div class="text-right">
                    <template v-if="row.item.status === 1">
                        <template v-if="role === 'GM General Affair'">
                            <b-button @click="handleApprove(row.item)" size="sm" class="mx-1" variant="primary">
                                Approve
                            </b-button>
                            <b-button @click="handleReject(row.item)" size="sm" class="mx-1" variant="danger">
                                Tolak
                            </b-button>
                        </template>
                        <template v-else>
                            menunggu approval
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="role === 'GM General Affair'">
                            <b-button @click="handleDelete(row.item)" size="sm" class="mx-1" variant="danger">
                                <i class="fa fa-trash"></i>
                            </b-button>
                            <b-button @click="handleEdit(row.item)" size="sm" class="mx-1" variant="warning">
                                <i class="fa fa-pencil"></i>
                            </b-button>
                        </template>
                        <template v-else>
                            <div>
                                <a class="btn btn-sm btn-primary" :href="row.item.link_merchant" target="_blank"
                                    rel="noopener noreferrer">
                                    <i class="fa fa-external-link" aria-hidden="true"></i>
                                </a>
                            </div>
                        </template>
                    </template>
                </div>
            </template>
        </b-table>
        <b-modal id="m-edit-merchant" size="md" centered title="Edit Merchant" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <b-form @submit.prevent="handleUpdateMerchant">
                <b-form-group label="Pilih Supplier">
                    <multiselect v-model="formEdit.supplier" class="w-100" placeholder="Pilih Suplier" label="nama"
                        track-by="id" :show-labels="false" :close-on-select="true" :multiple="false" :options="suppliers">
                    </multiselect>
                </b-form-group>
                <b-form-group label="Nama Merchant">
                    <b-input v-model="formEdit.nama_merchant" placeholder="Nama Seller"></b-input>
                </b-form-group>
                <b-form-group label="Link Merchant">
                    <b-input v-model="formEdit.link_merchant" placeholder="Link seller"></b-input>
                </b-form-group>
                <div class="d-flex justify-content-end">
                    <b-button type="submit" variant="pgiBtn">Simpan</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from '../../../config/api';
// import ModalEditMerchan from './ModalEditMerchan.vue';

export default {
    props: ["merchant", "suppliers"],
    // components: { ModalEditMerchan },
    data() {
        return {
            fields: [
                {
                    key: "nama_merchant",
                    label: "Nama Toko",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd"
                },
                {
                    key: "status",
                    label: "Status Toko",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd"
                },
                {
                    key: "link_merchant",
                    label: "Link Toko",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd"
                },
                {
                    key: "actions",
                    label: "Aksi",
                    thClass: "nameOfTheClassTh text-right",
                    tdClass: "nameOfTheClassTd"
                },
            ],
            merchantSelected: null,
            formEdit: {
                supplier: null,
                nama_merchant: null,
                link_merchant: null,
            },
            role: localStorage.getItem("role")
        };
    },
    methods: {
        handleDelete(data) {
            this.$swal({
                title: `Anda akan menghapus merchant, ` + data.nama_merchant,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Hapus",
                closeOnConfirm: false,
                closeOnCancel: false,
                reverseButtons: true,
            }).then(result => {
                if (result.isConfirmed) {
                    axiosClient({
                        method: 'DELETE',
                        url: 'supplier-merchant/' + data.id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$helper.toastSucc(this, "Berhasil menghapus merchant")
                            this.$store.dispatch("getMasterMerchant")
                        }
                    })
                }
            });
        },
        handleApprove(data) {
            this.$swal({
                title: `Apakah merchant, ` + data.nama_merchant + ` sudah sesuai`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Approve",
                closeOnConfirm: false,
                closeOnCancel: false,
                reverseButtons: true,
            }).then(result => {
                if (result.isConfirmed) {
                    axiosClient({
                        method: 'POST',
                        url: 'supplier-merchant/approval/' + data.id,
                        data: {
                            status: 2
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            this.$helper.toastSucc(this, "Berhasil approve merchant")
                            this.$store.dispatch("getMasterMerchant")
                        }
                    })
                }
            });
        },
        handleEdit(row) {
            this.merchantSelected = row
            this.formEdit = {
                supplier: row.supplier,
                nama_merchant: row.nama_merchant,
                link_merchant: row.link_merchant || ""
            }
            this.$bvModal.show('m-edit-merchant')
        },
        handleReject(data) {
            this.$swal({
                title: `Apakah ada akan menolak, ` + data.nama_merchant,
                type: "warning",
                showCancelButton: true,
                closeOnConfirm: false,
                closeOnCancel: false,
                reverseButtons: true,
            }).then(result => {
                if (result.isConfirmed) {
                    axiosClient({
                        method: 'POST',
                        url: 'supplier-merchant/approval/' + data.id,
                        data: {
                            status: 3
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            this.$helper.toastSucc(this, "Berhasil menolak merchant")
                            this.$store.dispatch("getMasterMerchant")
                        }
                    })
                }
            });
        },
        async handleUpdateMerchant() {
            const form = {
                supplier_id: this.formEdit.supplier.id,
                nama_merchant: this.formEdit.nama_merchant,
                link_merchant: this.formEdit.link_merchant
            }
            try {
                const res = await axiosClient({
                    method: 'POST',
                    url: 'supplier-merchant/' + this.merchantSelected.id,
                    data: form
                })
                this.$helper.toastSucc(this, "Berhasil merubah merchant")
                this.$store.dispatch("getMasterMerchant")
                this.$bvModal.hide('m-edit-merchant')
            } catch (error) {
                this.$helper.toastErr(this, error.response.data.message)
            }
        }
    },
    watch: {
        "formEdit.link_merchant": function (data) {
            this.formEdit.link_merchant = data.replace(/ /g, '')
        }
    }
}
</script>