<template>
    <div class="animated fadeIn">
        <b-card>
            <template v-slot:header>
                <h5>Master Toko Online</h5>
            </template>
            <div class="d-flex justify-content-end mb-3">
                <b-button @click="showModalCreate" variant="pgiBtn">
                    <i class="fa fa-plus"></i>
                    Tambah
                </b-button>
            </div>
            <TableMerchant :suppliers="suppliers" :merchant="merchants" />
        </b-card>
        <ModalCreateMerchan :suppliers="suppliers" />
    </div>
</template>

<script>
import TableMerchant from "../../../components/supplier/merchan/TableMerchant.vue"
import ModalCreateMerchan from "../../../components/supplier/merchan/ModalCreateMerchan.vue"
export default {
    components: {
        ModalCreateMerchan,
        TableMerchant
    },
    data() {
        return {
            role: localStorage.getItem("role")
        }
    },
    computed: {
        suppliers() {
            return this.$store.state.supplier.master_suppliers
        },
        merchants() {
            return this.$store.state.supplier.master_merchants
        }
    },
    methods: {
        showModalCreate() {
            this.$bvModal.show('m-create-merchant')
        }
    },
    mounted() {
        this.$store.dispatch("getMasterSupplier", { type_id: 1 })
        this.$store.dispatch("getMasterMerchant")
    }
}
</script>