<template>
    <div>
        <b-modal id="m-create-merchant" size="md" centered title="Tambah Merchant" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <b-form @submit.prevent="handleSubmitMerchant">
                <b-form-group label="Pilih Supplier">
                    <multiselect v-model="supplier" class="w-100" placeholder="Pilih Suplier" label="nama" track-by="id"
                        :show-labels="false" :close-on-select="true" :multiple="false" :options="suppliers">
                    </multiselect>
                </b-form-group>
                <b-form-group label="Nama Merchant">
                    <b-input v-model="nama_merchant" placeholder="Nama Seller"></b-input>
                </b-form-group>
                <b-form-group label="Link Merchant">
                    <b-input v-model="link_merchant" placeholder="Link seller"></b-input>
                </b-form-group>
                <div class="d-flex justify-content-end">
                    <b-button type="submit" variant="pgiBtn">Simpan</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from '../../../config/api'

export default {
    props: ['suppliers'],
    data() {
        return {
            supplier: null,
            nama_merchant: null,
            link_merchant: null,
            supplier_id: null,
        }
    },
    methods: {
        async handleSubmitMerchant() {
            const form = {
                nama_merchant: this.nama_merchant,
                link_merchant: this.link_merchant,
                supplier_id: this.supplier.id
            }
            try {
                const response = await axiosClient({
                    method: 'POST',
                    url: 'supplier-merchant',
                    data: form
                })
                if (response.status === 200) {
                    this.$helper.toastSucc(this, "Berhasil menambah merchant")
                    this.$store.dispatch("getMasterMerchant")
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                this.$bvModal.hide('m-create-merchant')
            }
        }
    },
    watch: {
        "link_merchant": function (data) {
            this.link_merchant = data.replace(/ /g, '')
        }
    }
}
</script>